<template>
    <!-- Error page-->
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">Error {{ code }}🕵🏻‍♀️</h2>
                <p class="mb-2">Oops! 😖 {{ message }}</p>

                <b-button variant="primary" class="mb-2 btn-sm-block" :to="{ path: '/' }"> Back to Page </b-button>

                <!-- image -->
                <b-img fluid :src="imgUrl" alt="Error page" />
            </div>
        </div>
    </div>
    <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import BusinessCode from '@/enums/BusinessCode'

export default {
    components: {
        VuexyLogo,
        BLink,
        BButton,
        BImg,
    },
    data() {
        return {
            appName: $themeConfig.app.appName,
            code: '1001',
            message: '錯誤訊息',
        }
    },
    computed: {
        imgUrl() {
            let image = require('@/assets/images/pages/error.svg')

            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                image = require('@/assets/images/pages/error-dark.svg')

                return image
            }

            return image
        },
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let code = this.$route.query.code

            if (code) {
                this.code = code
            }

            this.transferCodeToMessage(code)
        },
        transferCodeToMessage(code) {
            let message = BusinessCode.getEnumByValue(code)?.message

            if (message) {
                this.message = BusinessCode.getEnumByValue(code)?.message
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

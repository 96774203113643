export default class Enum {
    constructor(value, message) {
        this.value = value
        this.message = message
    }

    static getEnumByValue(value) {
        if (typeof value === 'string') {
            value = Number(value)
        }

        for (const key in this) {
            if (this[key].value === value) {
                return this[key]
            }
        }

        return null
    }

    static getValues() {
        return Object.values(this).map((item) => item.value)
    }
}
